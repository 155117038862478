if ('define' in window) {
define("discourse/theme-5/discourse/components/color-scheme-toggler", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse-common/helpers/i18n", "../lib/color-scheme-override", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _service, _dButton, _i18n, _colorSchemeOverride, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const settings = require("discourse/lib/theme-settings-store").getObjectForTheme(5);
  const themePrefix = key => `theme_translations.5.${key}`;
  class ColorSchemeToggler extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "keyValueStore", [_service.service]))();
    #keyValueStore = (() => (dt7948.i(this, "keyValueStore"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "session", [_service.service]))();
    #session = (() => (dt7948.i(this, "session"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "storedOverride", [_tracking.tracked], function () {
      return this.keyValueStore.getItem(_colorSchemeOverride.COLOR_SCHEME_OVERRIDE_KEY);
    }))();
    #storedOverride = (() => (dt7948.i(this, "storedOverride"), void 0))();
    get toggleButtonIcon() {
      switch (this.OSMode) {
        case "dark":
          return this.storedOverride === "light" ? "moon" : "sun";
        case "light":
          return this.storedOverride === "dark" ? "sun" : "moon";
      }
    }
    get OSMode() {
      return window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light";
    }
    toggleScheme() {
      switch (this.OSMode) {
        case "light":
          if (this.keyValueStore.getItem(_colorSchemeOverride.COLOR_SCHEME_OVERRIDE_KEY) === "dark") {
            this.keyValueStore.removeItem(_colorSchemeOverride.COLOR_SCHEME_OVERRIDE_KEY);
          } else {
            this.keyValueStore.setItem(_colorSchemeOverride.COLOR_SCHEME_OVERRIDE_KEY, "dark");
          }
          break;
        case "dark":
          if (this.keyValueStore.getItem(_colorSchemeOverride.COLOR_SCHEME_OVERRIDE_KEY) !== "light") {
            this.keyValueStore.setItem(_colorSchemeOverride.COLOR_SCHEME_OVERRIDE_KEY, "light");
          } else {
            this.keyValueStore.removeItem(_colorSchemeOverride.COLOR_SCHEME_OVERRIDE_KEY);
          }
          break;
      }
      this.storedOverride = this.keyValueStore.getItem(_colorSchemeOverride.COLOR_SCHEME_OVERRIDE_KEY) || null;
      // currently only used to flip category logos back/forth
      this.session.set("colorSchemeOverride", this.storedOverride);
      (0, _colorSchemeOverride.colorSchemeOverride)(this.storedOverride);
    }
    static #_4 = (() => dt7948.n(this.prototype, "toggleScheme", [_object.action]))();
    static #_5 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DButton
          @action={{this.toggleScheme}}
          @icon={{this.toggleButtonIcon}}
          @translatedTitle={{i18n (themePrefix "toggle_button_title")}}
          class="color-scheme-toggler btn-flat"
        />
      
    */
    {
      "id": null,
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"color-scheme-toggler btn-flat\"]],[[\"@action\",\"@icon\",\"@translatedTitle\"],[[30,0,[\"toggleScheme\"]],[30,0,[\"toggleButtonIcon\"]],[28,[32,1],[[28,[32,2],[\"toggle_button_title\"],null]],null]]],null],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/discourse/theme-5/discourse/components/color-scheme-toggler",
      "scope": () => [_dButton.default, _i18n.default, themePrefix],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ColorSchemeToggler;
});
}
