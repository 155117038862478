if ('define' in window) {
define("discourse/theme-5/discourse/connectors/home-logo-contents__after/minimized-hook", ["exports", "@glimmer/component", "@ember/object", "@ember/render-modifiers/modifiers/did-update", "@ember/service", "../../lib/color-scheme-override", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _didUpdate, _service, _colorSchemeOverride, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const settings = require("discourse/lib/theme-settings-store").getObjectForTheme(5);
  const themePrefix = key => `theme_translations.5.${key}`;
  class MinimizedHook extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "keyValueStore", [_service.service]))();
    #keyValueStore = (() => (dt7948.i(this, "keyValueStore"), void 0))();
    onMinimizedChange() {
      const storedOverride = this.keyValueStore.getItem(_colorSchemeOverride.COLOR_SCHEME_OVERRIDE_KEY);
      if (storedOverride) {
        (0, _colorSchemeOverride.changeHomeLogo)(storedOverride);
      }
    }
    static #_2 = (() => dt7948.n(this.prototype, "onMinimizedChange", [_object.action]))();
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <span
          class="hidden color-toggler-minimized-hook"
          {{didUpdate this.onMinimizedChange @outletArgs.minimized}}
        />
      
    */
    {
      "id": null,
      "block": "[[[1,\"\\n    \"],[11,1],[24,0,\"hidden color-toggler-minimized-hook\"],[4,[32,0],[[30,0,[\"onMinimizedChange\"]],[30,1,[\"minimized\"]]],null],[12],[13],[1,\"\\n  \"]],[\"@outletArgs\"],false,[]]",
      "moduleName": "/discourse/theme-5/discourse/connectors/home-logo-contents__after/minimized-hook",
      "scope": () => [_didUpdate.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = MinimizedHook;
});
}
