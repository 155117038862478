if ('define' in window) {
define("discourse/theme-5/discourse/connectors/sidebar-footer-actions/toggler-button", ["exports", "@glimmer/component", "@ember/service", "../../components/color-scheme-toggler", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _colorSchemeToggler, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const settings = require("discourse/lib/theme-settings-store").getObjectForTheme(5);
  const themePrefix = key => `theme_translations.5.${key}`;
  class TogglerButton extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "session", [_service.service]))();
    #session = (() => (dt7948.i(this, "session"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    get showInSidebar() {
      return (this.session.darkModeAvailable || this.siteSettings.default_dark_mode_color_scheme_id >= 0) && !settings.add_color_scheme_toggle_to_header;
    }
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.showInSidebar}}
          <ColorSchemeToggler />
        {{/if}}
      
    */
    {
      "id": null,
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"showInSidebar\"]],[[[1,\"      \"],[8,[32,0],null,null,null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/discourse/theme-5/discourse/connectors/sidebar-footer-actions/toggler-button",
      "scope": () => [_colorSchemeToggler.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = TogglerButton;
});
}
