if ('define' in window) {
define("discourse/theme-5/discourse/lib/color-scheme-override", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.COLOR_SCHEME_OVERRIDE_KEY = void 0;
  _exports.changeHomeLogo = changeHomeLogo;
  _exports.colorSchemeOverride = colorSchemeOverride;
  const settings = require("discourse/lib/theme-settings-store").getObjectForTheme(5);
  const themePrefix = key => `theme_translations.5.${key}`;
  const COLOR_SCHEME_OVERRIDE_KEY = _exports.COLOR_SCHEME_OVERRIDE_KEY = "color_scheme_override";
  function colorSchemeOverride(type) {
    const lightScheme = document.querySelector("link.light-scheme");
    const darkScheme = document.querySelector("link.dark-scheme") || document.querySelector("link#cs-preview-dark");
    if (!lightScheme && !darkScheme) {
      return;
    }
    switch (type) {
      case "dark":
        lightScheme.origMedia = lightScheme.media;
        lightScheme.media = "none";
        darkScheme.origMedia = darkScheme.media;
        darkScheme.media = "all";
        break;
      case "light":
        lightScheme.origMedia = lightScheme.media;
        lightScheme.media = "all";
        darkScheme.origMedia = darkScheme.media;
        darkScheme.media = "none";
        break;
      default:
        if (lightScheme.origMedia) {
          lightScheme.media = lightScheme.origMedia;
          lightScheme.removeAttribute("origMedia");
        }
        if (darkScheme.origMedia) {
          darkScheme.media = darkScheme.origMedia;
          darkScheme.removeAttribute("origMedia");
        }
        break;
    }
    changeHomeLogo(type);
  }
  function changeHomeLogo(type) {
    const logoDarkSrc = document.querySelector(".title picture source");
    if (!logoDarkSrc) {
      return;
    }
    switch (type) {
      case "dark":
        logoDarkSrc.origMedia = logoDarkSrc.media;
        logoDarkSrc.media = "all";
        break;
      case "light":
        logoDarkSrc.origMedia = logoDarkSrc.media;
        logoDarkSrc.media = "none";
        break;
      default:
        if (logoDarkSrc.origMedia) {
          logoDarkSrc.media = logoDarkSrc.origMedia;
        }
        break;
    }
  }
});
}
