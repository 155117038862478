if ('define' in window) {
define("discourse/theme-5/discourse/helpers/media-helper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  const settings = require("discourse/lib/theme-settings-store").getObjectForTheme(5);
  const themePrefix = key => `theme_translations.5.${key}`;
  function _default(value) {
    switch (value) {
      case "dark":
        return "all";
      case "light":
        return "none";
      default:
        return "(prefers-color-scheme: dark)";
    }
  }
});
}
