if ('define' in window) {
define("discourse/theme-5/discourse/initializers/color-scheme-toggler", ["exports", "@ember/owner", "@ember/runloop", "@ember/service", "discourse/lib/color-scheme-picker", "discourse/lib/plugin-api", "discourse/lib/theme-selector", "discourse-common/utils/decorators", "../components/color-scheme-toggler", "../lib/color-scheme-override", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _owner, _runloop, _service, _colorSchemePicker, _pluginApi, _themeSelector, _decorators, _colorSchemeToggler, _colorSchemeOverride, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const settings = require("discourse/lib/theme-settings-store").getObjectForTheme(5);
  const themePrefix = key => `theme_translations.5.${key}`;
  class TogglerInit {
    static #_ = (() => dt7948.g(this.prototype, "keyValueStore", [_service.service]))();
    #keyValueStore = (() => (dt7948.i(this, "keyValueStore"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "session", [_service.service]))();
    #session = (() => (dt7948.i(this, "session"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    constructor(owner) {
      (0, _owner.setOwner)(this, owner);
      const storedOverride = this.keyValueStore.getItem(_colorSchemeOverride.COLOR_SCHEME_OVERRIDE_KEY);
      if (!this.session.darkModeAvailable) {
        if (this.siteSettings.default_dark_mode_color_scheme_id <= 0) {
          // eslint-disable-next-line no-console
          console.warn("No dark color scheme available, the discourse-color-scheme-toggle component has no effect.");
          return;
        }
        (0, _colorSchemePicker.loadColorSchemeStylesheet)(this.siteSettings.default_dark_mode_color_scheme_id, (0, _themeSelector.currentThemeId)(), true).then(() => {
          if (storedOverride) {
            (0, _colorSchemeOverride.colorSchemeOverride)(storedOverride);
          } else {
            // ensures that this extra stylesheet isn't auto-used when OS is in dark mode
            document.querySelector("link#cs-preview-dark").media = "(prefers-color-scheme: none)";
          }
        });
      }
      if (storedOverride) {
        this.session.set("colorSchemeOverride", storedOverride);
      }
      if (this.session.darkModeAvailable && storedOverride) {
        (0, _runloop.schedule)("afterRender", () => {
          const logoDarkSrc = document.querySelector(".title picture source");
          // in some cases the logo widget is not yet rendered
          // so we schedule the calculation after a short delay
          if (!logoDarkSrc) {
            (0, _runloop.later)(() => {
              if (owner.isDestroying || owner.isDestroyed) {
                return;
              }
              (0, _colorSchemeOverride.colorSchemeOverride)(storedOverride);
            }, 500);
          } else {
            (0, _colorSchemeOverride.colorSchemeOverride)(storedOverride);
          }
        });
      }
      window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", this.onColorChange);
      if (settings.add_color_scheme_toggle_to_header) {
        (0, _pluginApi.withPluginApi)("1.28.0", api => {
          api.headerIcons.add("header-toggle-button", (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
          /*
            
                      <li class="header-toggle-button header-dropdown-toggle">
                        <span class="header-color-scheme-toggle icon">
                          <ColorSchemeToggler />
                        </span>
                      </li>
                    
          */
          {
            "id": null,
            "block": "[[[1,\"\\n            \"],[10,\"li\"],[14,0,\"header-toggle-button header-dropdown-toggle\"],[12],[1,\"\\n              \"],[10,1],[14,0,\"header-color-scheme-toggle icon\"],[12],[1,\"\\n                \"],[8,[32,0],null,null,null],[1,\"\\n              \"],[13],[1,\"\\n            \"],[13],[1,\"\\n          \"]],[],false,[]]",
            "moduleName": "/discourse/theme-5/discourse/initializers/color-scheme-toggler",
            "scope": () => [_colorSchemeToggler.default],
            "isStrictMode": true
          }), (0, _templateOnly.default)()), {
            before: "search"
          });
        });
      }
    }
    onColorChange() {
      // reset when switching OS dark mode
      this.keyValueStore.removeItem(_colorSchemeOverride.COLOR_SCHEME_OVERRIDE_KEY);
      this.session.set("colorSchemeOverride", null);
      (0, _colorSchemeOverride.colorSchemeOverride)();
    }
    static #_4 = (() => dt7948.n(this.prototype, "onColorChange", [_decorators.bind]))();
    teardown() {
      window.matchMedia("(prefers-color-scheme: dark)").removeEventListener("change", this.onColorChange);
    }
  }
  var _default = _exports.default = {
    name: "color-scheme-toggler",
    initialize(owner) {
      this.instance = new TogglerInit(owner);
    },
    teardown() {
      this.instance.teardown();
      this.instance = null;
    }
  };
});
}
